<template>
  <div class="profile-page">
    <h1 v-text="user.name" />
  </div>
</template>

<script>
export default {
  name: 'ProfilePage',
  data(){
    return {
      user: {}
    }
  },
  props: {
    profileId: {
      type: String,
      default: null
    }
  },
  async created(){
    this.show = await CreekStudio.get('users', this.profileId)
  }
}
</script>

<style scoped lang="sass">


</style>
